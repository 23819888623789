define("discourse/plugins/dis-6do-tts/discourse/templates/tts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="container">
    <div class="title-wrapper">
      <h1>
        {{html-safe (i18n "dis_6do_tts.heading")}}
      </h1>
    </div>
  
    <hr />
  
    {{outlet}}
  
    <br />
  </div>
  */
  {
    "id": "PB3m5Cey",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,3],[\"dis_6do_tts.heading\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h1\",\"html-safe\",\"i18n\",\"hr\",\"component\",\"-outlet\",\"br\"]]",
    "moduleName": "discourse/plugins/dis-6do-tts/discourse/templates/tts.hbs",
    "isStrictMode": false
  });
});